import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Header } from './components';
import { useEagerConnect, useInactiveListener } from './hooks/useEagerConnect';
import { Home } from './screens';


function App() {
  const [errorMessage, setErrorMessage] = useState();
  useEagerConnect(setErrorMessage);
  useInactiveListener();

  return (
    <div className="App">
      {/* {
        errorMessage? <div style={{color:"red"}}>{errorMessage}</div>: null
      } */}
      <BrowserRouter>
        <Header setErrorMessage={setErrorMessage} />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
