import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";
function Dvaultsdao() {
  return (
    <section id="Dvaultsdao" className="Dvaultsdao">
      <Container>
        <div className="sec-padding">
          <div className="Dvaultsdao-section-main">
            <Row>
              <Col lg={7}>
                <div className="Dvaults-dao-logo">
                  <div className="logo">
                    <img src={imageURL("homepage5.png")} alt="logo.jpg" />
                  </div>
                  <div className="text">
                    <h1>Univaults Grants DAO</h1>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="Dvaults-content">
                  <h6>
                    Univaults Grants DAO is a decentralized, community directed
                    grants program to allow for funding of innovation and new
                    protocols that will expand the Univaults ecosystem to build
                    on and expand the network of community and developers.
                  </h6>
                  <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/grants.md" target={"_blank"} className="link">
                    Apply for a Grant
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                  {/* <a href="#" className="link">
                    Subscribe to Aave News
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                  <a href="#" className="custom-btn secondary-btn">
                    Learn more
                  </a> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Dvaultsdao;
