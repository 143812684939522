import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";

export default function LiquidityLocked() {
    const [chainList,setChainList] = useState([
        {name:"BSC",percent:"70",value:0},
        {name:"ETH",percent:"50",value:0},
        {name:"KCC",percent:"40",value:0},
        {name:"Fantom",percent:"40",value:0},
        {name:"Avalance",percent:"40",value:0},
        {name:"Cardano",percent:"40",value:0},
        {name:"Polkadot",percent:"40",value:0},
        {name:"Solana",percent:"40",value:0}

    ]);


   

    return <>
        <Container>
            <section className="section black-bg background-section mb-5">
                <ul className="chain-list-head">
                    {
                        chainList.map((item)=>{
                            return <li>
                                {item.name}
                                <br/>
                                <p className="text-center m-0">
                                {"$"+item.value}
                                </p>
                            </li>
                        })
                    }
                </ul>
                <Row className="align-items-center">
                    <Col lg={4} md={6}>
                        <div className="upper-chart">
                        <div className="chart" >
                            <img src={imageURL('new-chart.svg')} className="img" style={{ marginTop: '1em' }}/>
                            <div className="inner-text">
                                <div className="text-center">
                                    <h5>Total Liquidity Locked</h5>
                                    <h6>$0</h6>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <p className="normal-text">
                        The Univaults treasury focuses on ecosystem growth and earns fees from transactions, staking, and liquidations. Total value locked in the ecosystem can be viewed on the left hand side.
                        </p>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="chart text-end">
                            <img src={imageURL('unicorn.png')} className="img" />
                        </div>
                    </Col>
                </Row>
            </section>
        </Container>

    </>
}