import { useState } from "react";
import { Container } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";

export default function Ecosystem() {
    const [tab, setTab] = useState('interface')
    return <>
        <section className="section">
            <Container>
                <h3 className="section-title">Univaults Ecosystem</h3>
                <div className="ecosystem-main">
                    <ul className="eco-tabs">
                        <li className={tab == "interface" && "active"} onClick={(e) => setTab('interface')}>
                            Interface
                        </li>
                        <li className={tab == "wallets" && "active"} onClick={(e) => setTab('wallets')}>
                            Wallets
                        </li>
                        <li className={tab == "yield" && "active"} onClick={(e) => setTab('yield')}>
                            Yield
                        </li>
                    </ul>
                    {
                        tab == "interface" ?
                        <div className="tab-items">
                                <ul className="coin-list">
                                    <li>
                                        <a href="#" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('homepage5.png')} />
                                                <span>Univaults</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://zapper.fi/" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('icons/zapper.svg')} />
                                                <span>Zapper</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://defisaver.com/" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('icons/defiSaver.svg')} />
                                                <span>Defi Saver</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://zerion.io/" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('icons/zerion.svg')} />
                                                <span>Zerion</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instadapp.io/" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('icons/instaDapp.svg')} />
                                                <span>InstaDapp</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://debank.com/" target="_blank">
                                            <div className="logo-main">
                                                <img src={imageURL('icons/deBank.svg')} />
                                                <span>DeBank</span>
                                            </div>
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                        </div> :
                        tab == "wallets" ?
                        <div className="tab-items">
                                    <ul className="coin-list">
                                        <li>
                                            <a href="https://www.ledger.com/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/ledger.svg')} />
                                                    <span>Ledger</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.coinbase.com/wallet" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/coinbase.svg')} />
                                                    <span>Coinbase Wallet</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.argent.xyz/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/argent.svg')} />
                                                    <span>Argent</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://frame.sh/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/frame.svg')} />
                                                    <span>Frame</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://trustwallet.com/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/trustwallet.svg')} />
                                                    <span>Trust Wallet</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://gnosis.io/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/gnosis.svg')} />
                                                    <span>Gnosis</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://metamask.io/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/metamask.png')} />
                                                    <span>Metamask</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://coin98.com/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/coin98.png')} />
                                                    <span>Coin 98</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                        </div> :
                        tab == "yield" &&
                        <div className="tab-items">
                                    <ul className="coin-list">
                                        <li>
                                            <a href="https://curve.fi/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/curve.svg')} />
                                                    <span>Curve</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.convexfinance.com/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/convex.svg')} />
                                                    <span>Convex</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://stakedao.org/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/stakeDao.svg')} />
                                                    <span>StakeDao</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://balancer.fi/" target="_blank">
                                                <div className="logo-main">
                                                    <img src={imageURL('icons/balancer.svg')} />
                                                    <span>Balancer</span>
                                                </div>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </li>
                                      
                                    </ul>
                        </div>
                    }
                    <div className="background-section half-white-bg section text-center custom-margin-section">
                        <h3 className="section-title2">Want to integrate your project/protocol with Univaults?</h3>
                        <p className="normal-text mt-3 mb-4">Our documentation has everything you need to know about API's and integration Endpoints so that you can integrate our technology into your own ecosystems.</p>
                        <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/docs-for-developers.md" target={"_blank"} className="custom-btn primary-btn">Documentation</a>
                    </div>
                </div>
            </Container>
        </section>
    </>
}