import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";
export default function BugBounty()
{
    return <>
        <section id="Dvaultsdao" className="Dvaultsdao">
      <Container>
        <div className="sec-padding">
          <div className="Dvaultsdao-section-main">
            <Row>
              <Col lg={7}>
                <div className="Dvaults-dao-logo">
                  
                  <div className="text">
                    <h1>Univaults Bug Bounty program</h1>
                  </div>
                  <div className="logo">
                    <img src={imageURL("bugbounty.jpg")} alt="logo.jpg" className="bounty-img"/>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="Dvaults-content">
                  <h6>
                  The bug bounty will be funded by the treasury of the Univaults protocol and funds gathered from the ecosystem fees. It will serve as a way to improve and secure the network in addition to providing rewards to developers or hackers that spot any vulnerabilities in the ecosystem in addition to offering a permanent position as a member of the team founders and core governors of the future direction of the protocol along with lifetime recognition and rewards from the ecosystem fees. 
                  </h6>
                    <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/bug-bounty.md" target="_blank" className="custom-btn secondary-btn">Learn More</a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
    </>
}