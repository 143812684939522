import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Market, NetworkList, Ecosystem, Asset,Howitwork, Dvaultsdao, BugBounty, FooterTop, Footer, LiquidityLocked, Partners } from "../components";
import imageURL from "../hooks/imageUrl";

function Home() {
  useEffect(()=>{
    // const video = document.querySelector('.banner-video');
    // if(video){
    //   video.play()
    // }
  },[])
  return (
    <>
      <section className="banner-section">
        <video muted autoPlay loop playsInline className="banner-video">
          <source src={imageURL("bg.mp4")} />
        </video>
        <Container>
          <div className="banner-inner">
            <img src={imageURL("icon-white.png")} className="icon" />
            <h3>Welcome to Univaults, a liquidity protocol market</h3>
            <h1>
              Earn the highest interest, borrow 100+ assets,
              <br />
              and build/host custom decentralized applications
              <br /> on our servers
            </h1>
          </div>
        </Container>
      </section>
      <NetworkList />
      <Market />
      <Howitwork />
      <Dvaultsdao />
      <Ecosystem/>
      <Asset/>
      <BugBounty/>
      <Partners/>
      <LiquidityLocked/>
      <FooterTop/>
      <Footer/>

    </>
  );
}

export default Home;
