import '../assets/css/footer.css'
import { Col, Container, Row } from "react-bootstrap";
import UnivaultsPaperPdf from '../assets/univaults-lite-paper.pdf'
export default function Footer() {
    return <>
        <footer className="custom-footer">
            <Container>
                <Row>
                    <Col lg={3} md={6}>
                        <ul className="social-links">
                            <li>
                                <a href="https://twitter.com/univaults" target={"_blank"}><i class="fa-brands fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="https://github.com/Univaults" target={"_blank"}><i class="fa-brands fa-github"></i></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCfjz-JyKXAyduvQXR_-1h2w" target={"_blank"}><i class="fa-brands fa-youtube"></i></a>
                            </li>
                            <li>
                                <a href="https://t.me/univaults" target={"_blank"}><i class="fa-brands fa-telegram"></i></a>
                            </li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6}>
                        <ul className="footer-links">
                            <li>
                                <a href="http://stake.univaults.io/farms" target={"_blank"}>Earn</a>
                            </li>
                            <li>
                                <a href="http://swap.univaults.io/" target={"_blank"}>Swap</a>
                            </li>
                            <li>
                                <a href="http://moneymarket.univaults.io" target={"_blank"}>Money Market</a>
                            </li>
                            <li>
                                <a href="http://market.univaults.io/" target={"_blank"}>Lend & Borrow</a>
                            </li>
                            <li>
                                <a href="http://bridge.univaults.io/" target={"_blank"}>Bridge</a>
                            </li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6}>
                        <ul className="footer-links">
                            <li>
                                <a href="https://github.com/Univaults/GITBOOK-Docs" target={"_blank"}>Technical Docs</a>
                            </li>
                            <li>
                                <a href= "https://github.com/Univaults/Litepaper/blob/main/Univaults%20Litepaper.pdf" target='_blank' rel='noopener noreferrer'>Litepaper</a>
                            </li>
                            <li>
                                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/uninomics.md" target={"_blank"}>Tokenomics</a>
                            </li>
                            <li>
                                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/grants.md" target="_blank">Careers</a>
                            </li>
                      
                        </ul>
                    </Col>
                    <Col lg={3} md={6}>
                        <ul className="footer-links">
                            <li>
                                <a href="https://github.com/Univaults/Branding" target="_blank">Branding</a>
                            </li>
                            <li>
                                <a href="https://t.me/defiapelucias" target="_blank">Contact</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    </>
}