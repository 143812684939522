import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function NetworkList() {
    const [slider, setSlider] = useState(25);
    const [tab, setTab] = useState('supply')
    const sliderEvent = (per, tab) => {
        setSlider(per)
        setTab(tab)
    }
    return <>
        <section className='section'>
            <Container>
                <Row>
                    {/* <Col lg={10}>
                        <ul className='network-list'>
                            <li>
                                Binance smart chain - ca
                            </li>
                            <li>
                                Ethereum - ca TBA
                            </li>
                            <li>
                                Ku coin chain - ca TBA
                            </li>
                            <li>
                                Fantom chain - ca TBA
                            </li>
                            <li>
                                Avalance - ca TBA
                            </li>
                            <li>
                                Cardano - ca TBA
                            </li>
                            <li>
                                Polkadot - ca TBA
                            </li>
                            <li>
                                Solana - ca TBA
                            </li>
                        </ul>
                    </Col> */}
                    <Col lg={12}>
                        <div className='supply-slider'>
                            <div className='slider-width'>
                                <ul>
                                    <li>
                                        <a href='#' onClick={(e) => sliderEvent(25, 'supply')} className={tab == "supply" && "active"}>Supply</a>
                                    </li>
                                    <li>
                                        <a href='#' onClick={(e) => sliderEvent(50, 'stake')} className={tab == "stake" && "active"}>Stake</a>
                                    </li>
                                    <li>
                                        <a href='#' onClick={(e) => sliderEvent(75, 'borrow')} className={tab == "borrow" && "active"}>Borrow</a>
                                    </li>
                                    <li>
                                        <a href='#' onClick={(e) => sliderEvent(100, 'vote')} className={tab == "vote" && "active"}>Vote</a>
                                    </li>
                                </ul>
                                <div className='percent-div'>
                                    <div className='inner' style={{ width: slider + '%' }}>

                                    </div>
                                </div>
                                <div className='slider-body'>
                                    {
                                        tab == "supply" ?
                                            <p className='big-normal-text'>
                                                Supply into the protocol and watch your assets grow as a liquidity provider
                                            </p> :
                                            tab == "stake" ?
                                                <p className='big-normal-text'>
                                                    Deposit your Univaults into the protocol and earn rewards for securing the protocol
                                                </p> :
                                                tab == "borrow" ?
                                                    <p className='big-normal-text'>
                                                        Borrow against your collateral from across multiple networks and assets
                                                    </p> :
                                                    tab == "vote" &&
                                                    <p className='big-normal-text'>
                                                        Participate in Univaults governance and vote on new proposals, new assets, and protocol upgrades
                                                    </p>
                                    }

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
}