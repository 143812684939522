import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";
export default function Partners()
{
    return <>
        <section id="Dvaultsdao" className="Dvaultsdao">
      <Container>
        <h3 className="section-title text-center">
            Our Partners
        </h3>
        <div className="parnters mt-5">
            <ul>
                {/* <li>
                    <a href="#">
                        <img src={imageURL('partners/aads.webp')}/>
                    </a>
                </li> */}
                <li>
                    <a href="#">
                        <img src={imageURL('partners/bitrmart.webp')}/>
                    </a>
                </li>
         
                {/* <li>
                    <a href="#">
                        <img src={imageURL('partners/coinsniper.png')}/>
                    </a>
                </li> */}
                {/* <li>
                    <a href="#">
                        <img src={imageURL('partners/coinzilla.png')}/>
                    </a>
                </li> */}
                {/* <li>
                    <a href="#">
                        <img src={imageURL('partners/dextools.png')}/>
                    </a>
                </li> */}
                <li>
                    <a href="#">
                        <img src={imageURL('partners/gate-startup.webp')}/>
                    </a>
                </li>
              
                <li>
                    <a href="#">
                        <img src={imageURL('partners/pinksale.png')}/>
                    </a>
                </li>
                {/* <li>
                    <a href="#">
                        <img src={imageURL('partners/watcherguru.webp')}/>
                    </a>
                </li> */}
                <li>
                    <a href="#">
                        <img src={imageURL('partners/yahoofinance.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/idopresales.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/bscnews.png')}/>
                    </a>
                </li>
                {/* ADDITION */}
                <li>
                    <a href="#">
                        <img src={imageURL('partners/bkex-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/cg-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/cmc-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/cryptorank-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/dappradar-main-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/hotbit-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/icoholder-new.png')}/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={imageURL('partners/nomics-new.png')}/>
                    </a>
                </li>
            </ul>
        </div>
      </Container>
    </section>
    </>
}