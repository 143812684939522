import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";
function Howitwork() {
  return (
    <section id="open-source-community" className="open-source-community">
      <Container>
        <div className="governance-section">
          <div className="text-center">
            <h3 className="section-title mb-3">
              An open source protocol Governed by the community
            </h3>
            <p className="normal-text mb-4">
              Univaults is a fully decentralized,
              <br />
              community governed protocol that is fully ran by its holders.
            </p>
            <a href="http://www.governance.univaults.io/" target={'_blank'} className="custom-btn secondary-btn">
              Governance Forum
            </a>
          </div>
          <img src={imageURL("governance.png")} />
        </div>
        <div className="how-it-work">
          <Row>
            <Col lg={4} md={4}>
              <div className="box">
                <div className="number">
                  <hr />
                  <span>1</span>
                </div>
                <h6>Submit a Proposal request form </h6>
                <p>
                  Get feedback on the proposal and discuss and share ideas with
                  community members
                </p>
                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/voting.md" target={"_blank"}>
                  {" "}
                  Visit Docs
                </a>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="box">
                <div className="number">
                <hr/>
                  <span>2</span>
                </div>
                <h6>Submit Request for comment</h6>
                <p>Create a snapshot to elaborate on the idea</p>
                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/voting.md" target={"_blank"}>
                  {" "}
                  Visit Docs
                </a>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="box">
                <div className="number">
                <hr/>
                  <span>3</span>
                </div>
                <h6>Submit a Univaults request for improvement</h6>
                <p>
                  The Proposal is submitted through a Github Pull request and
                  the community votes on approvals
                </p>
                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/voting.md" target={"_blank"}>
                  {" "}
                  Visit Docs
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Howitwork;
