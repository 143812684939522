import { useWeb3React } from '@web3-react/core';
import { connectWallet } from "../utils/connectWallet";

import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../assets/css/header.css'
import imageURL from '../hooks/imageUrl'
function Header(props) {
    const {
        active,
        activate,
    } = useWeb3React();
    const [nav, setNav] = useState(false);
    const [headerMode, setHeaderMode] = useState('normal')
    useEffect(() => {
        window.addEventListener('scroll', function () {
            var header = document.querySelector(".custom-header")
            if (window.scrollY > 0) {
                //   setHeaderMode('white')
            }
            else {
                //   setHeaderMode('normal')
            }
        });
    }, []);

    const handleLink = (event) => {
        if(!active){
            event.stopPropagation()
            event.preventDefault();
            connectWallet(activate, props.setErrorMessage);
        }
    }

    return <>
        <header className={'custom-header ' + headerMode}>
            <div className='logo-section'>
                <img src={headerMode == 'normal' ? imageURL('logo-white.png') : imageURL('logo.png')} className="logo" />
                <i class="fa-solid fa-bars head-icon open-sidebar-icon mobile-b" onClick={() => setNav(true)}></i>
            </div>
            <div className={'header-body ' + (nav ? 'open-sidebar' : 'close-sidebar')}>
                <div className='mobile-logo-section'>
                    <img src={headerMode == 'normal' ? imageURL('logo-white.png') : imageURL('logo.png')} className="logo" />
                    <i class="fa-solid fa-xmark head-icon" onClick={() => setNav(false)}></i>
                </div>
                <ul className='nav'>
                    <li className='nav-link'>
                        <a href={'https://github.com/univaults'} target="_blank" className="link">Open Source</a>
                    </li>
                    <li className='nav-link'>
                        <a href={'http://www.governance.univaults.io/'} target="_blank" className="link">Governance</a>
                    </li>
                    <li className='nav-link'>
                        <a target={'_blank'} href={'https://github.com/Univaults/GITBOOK-Docs'} className="link">Docs</a>
                    </li>
                    <li className='nav-link'>
                        <a target={'_blank'} href={'https://github.com/Univaults/GITBOOK-Docs/blob/docs/audits.md'} className="link">Security</a>
                    </li>
                    {/* <li className='nav-link'>
                    <Link to={'#'} className="link">FAQ</Link>
                </li> */}
                    <li className='nav-link'>
                        <a href={'http://swap.univaults.io/'} target="_blank" className="link">Buy UNVT</a>
                    </li>
                </ul>
                <div className='launch-app mobile-b text-center mt-3'>
                    {/* {
                        active ?
                        <Dropdown className='custom-dropdown-main'>
                        <Dropdown.Toggle className='custom-btn secondary-btn' id="dropdown-basic">
                            Launch App
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="http://market.univaults.io/" target="_blank">Market</Dropdown.Item>
                            <Dropdown.Item href="http://swap.univaults.io/" target="_blank">Swap</Dropdown.Item>
                            <Dropdown.Item href="http://stake.univaults.io/" target="_blank">Stake</Dropdown.Item>
                            <Dropdown.Item href="http://bridge.univaults.io/" target="_blank">Bridge</Dropdown.Item>
                            <Dropdown.Item href="http://governance.univaults.io/" target="_blank">Governance</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>:
                    <a href='http://moneymarket.univaults.io/' target={"_blank"} className='custom-btn secondary-btn'>Launch App</a> 
                    } */}
                        <Dropdown className='custom-dropdown-main'>
                        <Dropdown.Toggle className='custom-btn secondary-btn' id="dropdown-basic">
                            Connect Apps
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="http://moneymarket.univaults.io/" target="_blank">Market</Dropdown.Item>
                            <Dropdown.Item href="http://swap.univaults.io/" target="_blank">Swap</Dropdown.Item>
                            <Dropdown.Item href="http://stake.univaults.io/" target="_blank">Stake</Dropdown.Item>
                            <Dropdown.Item href="http://bridge.univaults.io/" target="_blank">Bridge</Dropdown.Item>
                            <Dropdown.Item href="http://governance.univaults.io/" target="_blank">Governance</Dropdown.Item>
                            {/* <Dropdown.Item href="http://governance.univaults.io/" target="_blank">
                                <a href='http://moneymarket.univaults.io/' target={"_blank"} className='drop-down-link'>Connect Apps</a> 
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                    
                </div>
            </div>
            <div className='launch-app desktop-b'>
                {/* {
                    active ?
                        <Dropdown className='custom-dropdown-main'>
                            <Dropdown.Toggle className='custom-btn secondary-btn' id="dropdown-basic">
                                Launch App
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="http://market.univaults.io/" target="_blank">Market</Dropdown.Item>
                                <Dropdown.Item href="http://swap.univaults.io/" target="_blank">Swap</Dropdown.Item>
                                <Dropdown.Item href="http://stake.univaults.io/" target="_blank">Stake</Dropdown.Item>
                                <Dropdown.Item href="http://bridge.univaults.io/" target="_blank">Bridge</Dropdown.Item>
                                <Dropdown.Item href="http://governance.univaults.io/" target="_blank">Governance</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> :
                        <a href='#' className='custom-btn secondary-btn' onClick={() => { connectWallet(activate, props.setErrorMessage); }}>Connect Wallet</a>
                } */}
          
                        <Dropdown className='custom-dropdown-main'>
                            <Dropdown.Toggle className='custom-btn secondary-btn' id="dropdown-basic">
                                Connect Apps
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="http://moneymarket.univaults.io/" target="_blank">Market</Dropdown.Item>
                                <Dropdown.Item href="http://swap.univaults.io/" target="_blank">Swap</Dropdown.Item>
                                <Dropdown.Item href="http://stake.univaults.io/" target="_blank">Stake</Dropdown.Item>
                                <Dropdown.Item href="http://bridge.univaults.io/" target="_blank">Bridge</Dropdown.Item>
                                <Dropdown.Item href="http://governance.univaults.io/" target="_blank">Governance</Dropdown.Item>
                                {/* <Dropdown.Item href="http://governance.univaults.io/" target="_blank">
                                <a href='http://moneymarket.univaults.io/' target={"_blank"} className='drop-down-link'>Connect Apps</a> 
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown> 

            </div>
        </header>
    </>
}

export default Header