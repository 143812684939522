import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import imageURL from "../hooks/imageUrl";

export default function Market() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    autoplay: false,
    draggable: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="section ">
        <Container>
          <h3 className="section-title">UNIVAULTS MARKETS</h3>
          <Slider {...settings}>
            <div>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("logo_BNB_Chain.svg")} className="logo" />
                  <p className="text-small p-md-3">
                    0xBb551B0AF0B4cDb4d7A013BD3282edB304b22945 
                  </p>
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://www.bnbchain.org/en/smartChain"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Binance Smart Chain</span>{""}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  A dual-chain architecture that empowers its users to build
                  their decentralized apps and digital assets on one blockchain
                  and take advantage of the fast trading to exchange on the
                  other.
                </p>
              </article>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("eth.png")} className="logo" />
                  <p className="text-small p-md-3">
                  0x6fc5187Ee70299F6F9E6e7726926BC63c4c6f3C4 
                  </p>
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://ethereum.org/en/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Ethereum</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Ethereum is the largest community-run technology powering the
                  cryptocurrency ether (ETH) and thousands of decentralized
                  applications.
                </p>
              </article>
            </div>
            <div>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("KuCoin.svg")} className="logo" />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://www.kcc.io/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Ku Coin Community Chain</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Built by the fans of KCS and KuCoin's fan communities,KCC is a
                  decentralized public chain with EVM compatible and high
                  performance.
                </p>
              </article>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img
                    src={imageURL("fantom-logo-white.svg")}
                    className="logo"
                  />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://fantom.foundation/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Fantom</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Fantom is a fast, high-thgroughput open-source smart contract
                  platform for digital assets and dApps.
                </p>
              </article>
            </div>
            <div>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("avalanche.png")} className="logo" />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://www.avax.network/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Avalanche</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Fast and cheaper transactions. Earn rewards in Avax for
                  borrowing or supplying liquidity.
                </p>
              </article>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("cardano_SS2_logo.png")} className="logo" />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://cardano.org/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Cardano</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Cardano is a blockchain platform for changemakers, innovators,
                  and visionaries, with the tools and technologies required to
                  create possibility for the many, as well as the few.
                </p>
              </article>
            </div>
            <div>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("logo-polkadot.svg")} className="logo" />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://polkadot.network/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Polkadot</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Polkadot unites and secures a growing ecosystem of specialized
                  blockchains called parachains. Apps and services on Polkadot
                  can securely communicate across chains.
                </p>
              </article>
              <article className="market-custom-card">
                <div className="card-logo-main">
                  <img src={imageURL("solana.svg")} className="logo" />
                  <span className="market-link">
                    Go to Market <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
                <a
                  href="https://solana.com/"
                  target={"_blank"}
                  className="link-tab"
                >
                  <span>Solana</span>{" "}
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
                <p>
                  Solana is a decentralized blockchain built to enable scalable,
                  user-friendly apps for the world, with thousands of projects
                  spanning DeFi, NFTs, Web3 and more.
                </p>
              </article>
            </div>
          </Slider>
        </Container>
      </section>
    </>
  );
}
