import { Container } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";


export default function FooterTop() {
    return <>
        <section className="footer-top-bg background-section section">
            <Container>
                <div className="footer-top">
                    <div className="f-logo-section">
                        <img src={imageURL('logo-white.png')} alt="" />
                    </div>
                    <div className="f-links-body">
                        <ul>
                            <li>
                                <a href="https://github.com/Univaults" target={"_blank"}>Open Source</a>
                            </li>
                            <li>
                                <a href="http://www.governance.univaults.io/" target={"_blank"}>Governance</a>
                            </li>
                            <li>
                                <a href="https://github.com/Univaults/GITBOOK-Docs" target={"_blank"}>Docs</a>
                            </li>
                            <li>
                                <a href="https://github.com/Univaults/GITBOOK-Docs/blob/docs/audits.md" target={"_blank"}>Security</a>
                            </li>
                            {/* <li>
                                <a href="">FAQ</a>
                            </li> */}
                            <li>
                                <a href="http://swap.univaults.io/" target={"_blank"}>Buy UNVT</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    </>
}