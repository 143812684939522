import { Container, Row, Col } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";

export default function Asset() {
    return <>
        <section className="section">
            <Container>
                <div className="asset-row">
                    <div className="asset-col">
                    <h3 className="section-title">Your assets, secured to the highest standards in the blockchain industry</h3>
                    <p className="normal-text">
                    The protocol and our foundations are Audited by the worlds most proficient and recognized security firms. The security of our ecosystem is of the highest priority.
                    </p>
                    </div>
                    <div className="asset-col">
    
                    <div className="asset-main">
                    <div className="assets">
                        <a href="#/" className="inner">
                            <div class="SecuredSection__itemBg MuiBox-root css-1xmmy0w" bis_skin_checked="1"></div>
                            <div class="MuiBox-root css-fohzfn" bis_skin_checked="1"></div>
                            <img class="MuiBox-root css-arn168" src={imageURL('audit1.png')} />
                        </a>
                        <a href="#/" className="inner">
                            <div class="SecuredSection__itemBg MuiBox-root css-1xmmy0w" bis_skin_checked="1"></div>
                            <div class="MuiBox-root css-fohzfn" bis_skin_checked="1"></div>
                            <a  href="https://coinsult.net/projects/univaults/" target="_blank">
                                <img class="MuiBox-root css-arn168" src={imageURL('coinsult.svg')} />
                            </a>
                        </a>
                        <a  href="#/" className="inner show-lg">
                            <div class="SecuredSection__itemBg MuiBox-root css-1xmmy0w" bis_skin_checked="1"></div>
                            <div class="MuiBox-root css-fohzfn" bis_skin_checked="1"></div>
                            <img class="MuiBox-root css-arn168" src={imageURL('audit3.png')} />
                        </a>
                    </div>
                    <div className="assets show-md">
                        <a href="#" className="inner">
                            <div class="SecuredSection__itemBg MuiBox-root css-1xmmy0w" bis_skin_checked="1"></div>
                            <div class="MuiBox-root css-fohzfn" bis_skin_checked="1"></div>
                            <img class="MuiBox-root css-arn168" src={imageURL('audit3.png')} />
                        </a>

                    </div>
                    </div>
                    </div>
                </div>
            
            </Container>
        </section>
    </>
}